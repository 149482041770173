<template>
    <div>
        <v-card flat>
          <div>
            <v-container class="grey lighten-5 fill-height">
                <v-row
                v-for="n in 1"
                :key="n"
                :class="n === 1 ? 'mb-6' : ''"
                no-gutters>
                <v-col style="margin-right: 16px">
                    <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 24px; padding-bottom: 5px; max-height: 260px">
                        <v-row class="mb-6 align-center" no-gutters>
                            <v-col :md="4" class="text-center">
                              <div class="px-11 mb-2">
                                <v-avatar class="pa-3" size="88" tile>
                                    <v-icon size="88">$vuetify.icons.store_orange</v-icon>
                                </v-avatar>
                              </div>
                              <span class="font-weight-bold">580</span>
                              <v-spacer></v-spacer>
                              <span class="body-2" style="color: #E8B600">{{ lang.t('$vuetify.inactive_merchant') }}</span>
                              <p class="caption" style="color: #D4D4D4">from last 7 days</p>
                            </v-col>
                            <v-col :md="5" >
                                <div v-for="item in merchantList" :key="item" style="min-height: 40px; margin-bottom: 8px">
                                    <div style="margin-bottom: 5px">
                                        <span>{{ item.city }}</span>
                                        <span class="float-right">{{ item.persen }} %</span>
                                    </div>
                                    <v-progress-linear color="#E8B600" :value="item.persen"></v-progress-linear>
                                </div>
                            </v-col>
                            <v-col :md="2" style="padding-left: 5px;">
                                <div v-for="item in merchantList" :key="item" class="text-right" style="height: 40px; margin-bottom: 8px">
                                    <span style="color: #FDD648">{{ item.stok }}</span>
                                    <p class="caption" style="color: #BEC2C4">{{ item.status }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col >
                    <v-card class="fill-height" :shaped="true" :raised="true" style=" padding: 24px; max-height: 260px">
                      <span class="title" style="color: #E8B600;">{{ lang.t('$vuetify.top_longest_inactive') }}</span><br>
                      <div v-for="(item, id) in desserts" :key="id" style="min-height: 32px; margin-bottom: 4px; margin-top: 10px">
                        <div style="margin-bottom: 5px">
                            <span>{{ item.name }}</span>
                            <p class="float-right subtitle-2" style="color: #888888">{{ item.time_left }} ago</p>
                        </div>
                      </div>
                    </v-card>
                </v-col>
                </v-row>
                <v-card class="pa-2" :shaped="true" :raised="true" width="100%">
                <v-card-title style="height: 64px">
                    <v-row class="mb-6" no-gutters>
                    <v-col :md="6">
                        {{ lang.t('$vuetify.merchant_activity') }}
                    </v-col>
                    <v-col :md="4">
                        <div class="float-right" style="margin-right: 8px">
                        <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                            :value="computedDateFormattedMomentjs"
                            readonly
                            v-on="on"
                            solo
                            background-color="#F5F5F5"
                            style="width: 200px"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            @click:date="setDate(0)"
                            range
                        ></v-date-picker>
                        </v-menu>
                        </div>
                    </v-col>
                    <v-col :md="2">
                        <v-autocomplete
                        v-model="selectCity"
                        :items="dropdown_font"
                        solo
                        label="All City"
                        background-color="#F5F5F5"
                        ></v-autocomplete>
                    </v-col>
                    </v-row>
                </v-card-title>
                <v-tabs
                  v-model="tab2"
                  align-with-title
                  background-color="transparent">
                  <v-tabs-slider color="yellow"></v-tabs-slider>

                  <v-tab v-for="item in itemsTab" :key="item">
                  {{ item }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab2">
                  <v-tab-item>
                    <ve-line :data="dataLine" :legend-visible="false" :colors="colorsLine" :settings="lineSettings" :extend="extend" :xAxis="xaxis" />
                    <v-card class="pa-2" :shaped="true" >
                      <v-card-title>
                        12 {{ lang.t('$vuetify.merchant') }}
                        <v-spacer></v-spacer>
                        <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>Download</v-btn>
                        <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>Print</v-btn>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                          style="width: 10px;"
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        :headers="headers"
                        :items="desserts"
                        :search="search"
                        hide-default-footer
                        class="mytable"
                        style="border-bottom: none"
                      >
                      <template v-slot:header.name="{ header }">
                        <span style="color: #00B082">{{ header.text }}</span>
                      </template>
                      <template v-slot:item.name="{ item }">
                        {{ item.name }}
                      </template>
                      <template v-slot:item.last_activity="{ item }">
                        {{ item.last_activity }}
                        <span style="color: #00B082">({{ item.flast_activity }})</span>
                      </template>
                      <template v-slot:item.member_status="{ item }">
                        <div v-if="item.member_status === 1">
                            <v-chip color="#EEF5FA" label text-color="#2980B9" style="width: 80px" >Premium</v-chip>
                        </div>
                        <div v-else >
                            <v-chip color="#E4E4E4" label text-color="#6A6A6A" style="width: 80px" class="ma-2">Free</v-chip>
                        </div>
                      </template>
                      </v-data-table>

                      <v-spacer></v-spacer><br>
                      <v-pagination
                        v-model="page"
                        color="#00B082"
                        :length="total_page"
                        :total-visible="7"
                        circle
                        ></v-pagination>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
                </v-card>
            </v-container>
            </div>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

export default {
  name: 'ListActivity',
  props: ['xaxis', 'extend', 'page', 'total_page'],

  data () {
    return {
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        }
      ],
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.merchant_name'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.joined_date'),
          align: 'right',
          value: 'joined_date'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.last_activity'),
          align: 'center',
          value: 'last_activity'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.member_status'),
          align: 'center',
          value: 'member_status'
        }
      ],
      desserts: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS',
          last_activity: '21 Desember 2019 13.00',
          flast_activity: '5 days ago',
          joined_date: '21 Agustus 2019',
          time_left: '2 days',
          member_status: 1
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online',
          last_activity: '21 Desember 2019 13.00',
          flast_activity: '5 days ago',
          joined_date: '21 Agustus 2019',
          time_left: '2 days',
          member_status: 2
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online, Marketplace',
          last_activity: '21 Desember 2019 13.00',
          flast_activity: '5 days ago',
          joined_date: '21 Agustus 2019',
          time_left: '2 days',
          member_status: 1
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          total_staff: 12,
          platform: 'POS',
          last_activity: '21 Desember 2019 13.00',
          flast_activity: '5 days ago',
          joined_date: '21 Agustus 2019',
          time_left: '2 days',
          member_status: 1
        }
      ],
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      tab2: null,
      itemsTab: [
        'All', 'POS', 'Online', 'Marketplace'
      ],
      dataLine: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 },
          { 'date': '12', 'sales': 30 },
          { 'date': '13', 'sales': 40 }
        ]
      },
      colorsLine: ['#00D79E', '#1DA1F3'],
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        },
        label: {
          normal: {
            show: true,
            position: 'top'
          }
        }
      },
      search: ''
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    },

    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    }
  }
}
</script>
