<template>
  <v-container class="fill-height">
    <div class="mx-4 mb-4" :raised="true" style="width: 100%">
      <v-row align="center" justify="center" no-gutters>
        <v-col :md="4" class="mr-4">
            <v-card class="py-6" :raised="true" height="120px">
                <div class="align-center text-center">
                <div class="title font-regular" style="color: #BEC2C4">Total Merchant</div>
                <span class="display-1 font-weight-bold" style="color: #00D79E">Rp. 1500</span>
                </div>
                <div style="position: absolute; bottom: 0px; background: #00D79E; height: 8px; border-top-right-radius: 5px; border-top-left-radius: 5px; width: 100%"></div>
            </v-card>
        </v-col>
        <v-col style="margin-right: 16px">
            <v-card class="fill-height" :raised="true" height="120px">
                <v-row justify="center" align="center" no-gutters>
                    <v-col :md="4" class="mx-3">
                      <div class="py-3 px-5 mt-6" style="background: #EBFCF7; width: 68px; height:68px; border-radius: 50%;">
                          <v-icon color="#00D79E" :x-large="true">$vuetify.icons.test</v-icon>
                      </div>
                    </v-col>
                    <v-col class="ml-2" align-self="center">
                      <div class="mt-5">
                        <span class="headline font-weight-bold" style="color: #00D79E">1654</span><br>
                        <span class="caption font-regular" style="color: #BEC2C4">Post</span>
                      </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col style="margin-right: 16px">
            <v-card class="fill-height" :raised="true" height="120px">
                <v-row justify="center" align="center" no-gutters>
                    <v-col class="ml-3">
                      <div class="py-3 px-5 mt-6" style="background: #EBFCF7; width: 68px; height:68px; border-radius: 50%;">
                          <v-icon color="#00D79E" :x-large="true">$vuetify.icons.test</v-icon>
                      </div>
                    </v-col>
                    <v-col align-self="center">
                        <span class="headline font-weight-bold" style="color: #00D79E">1654</span><br>
                        <span class="caption font-regular" style="color: #BEC2C4">Post</span>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col style="margin-right: 16px">
            <v-card class="fill-height" :raised="true" height="120px">
                <v-row justify="center" align="center" no-gutters>
                    <v-col class="ml-3">
                      <div class="py-3 px-5 mt-6" style="background: #EBFCF7; width: 68px; height:68px; border-radius: 50%;">
                          <v-icon color="#00D79E" :x-large="true">$vuetify.icons.test</v-icon>
                      </div>
                    </v-col>
                    <v-col align-self="center">
                        <span class="headline font-weight-bold" style="color: #00D79E">1654</span><br>
                        <span class="caption font-regular" style="color: #BEC2C4">Post</span>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        </v-row>
            <p class="headline" style="margin-left: 24px">Merchant Transaction</p>
            <v-row align="center">
                <v-col :md="4">
                    <ve-ring :data="dataRing" width="310px" :legend-visible="false" :settings="ringSettings" :colors="colorsRing" :title="title" />
                </v-col>
                <v-col>
                    <div v-for="(item, key) in merchantList" :key="key" style="min-height: 40px; margin-bottom: 8px; margin-right: 24px">
                        <div style="margin-bottom: 5px">
                            <span class="title font-weight-regular" style="color: #6A6A6A">{{ item.city }}</span>
                            <span class="title float-right font-weight-regular" style="color: #6A6A6A">{{ item.persen }} %</span>
                        </div>
                        <v-progress-linear :value="item.persen" height="6px" rounded :color="colorsRing[key]"></v-progress-linear>
                    </div>
                </v-col>
            </v-row>
    </div>
    <div class="mx-4 mt-5" style="width: 100%">
      <div style="height: 64px;">
        <v-row class="mb-6" no-gutters>
        <v-col :md="6">
          <span class="title">  Merchant Transaction </span>
        </v-col>
        <v-col :md="4">
            <div class="float-right" style="margin-right: 8px">
            <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" >
            <template v-slot:activator="{ on }">
                <v-text-field
                :value="computedDateFormattedMomentjs"
                readonly
                v-on="on"
                solo
                background-color="#F5F5F5"
                style="width: 200px"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                @click:date="setDate(0)"
                range
            ></v-date-picker>
            </v-menu>
            </div>
        </v-col>
        <v-col :md="2">
            <v-autocomplete
            v-model="selectCity"
            :items="dropdown_font"
            solo
            label="All City"
            background-color="#F5F5F5"
            ></v-autocomplete>
        </v-col>
        </v-row>
    </div>
    <v-tabs
      v-model="tab2"
      align-with-title
      background-color="transparent">
      <v-tabs-slider color="yellow"></v-tabs-slider>

      <v-tab v-for="item in itemsTab" :key="item">
      {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab2">
      <v-tab-item>
        <ve-line :data="dataLine" :legend-visible="true" :colors="colorsLine" :settings="lineSettings" :extend="extend" :xAxis="xaxis" :after-config="afterConfig" />
        <v-card class="pa-2" :shaped="true" >
          <v-card-title>
            12456 Transaction
            <v-spacer></v-spacer>
            <v-btn rounded outlined style="margin-right: 8px"><v-icon left>mdi-cloud-download</v-icon>Print</v-btn>
            <v-btn rounded outlined style="margin-right: 8px"><v-icon left>mdi-printer</v-icon>Print</v-btn>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              style="width: 10px;"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            hide-default-footer
            class="mytable"
            style="border-bottom: none"
          >
          <template v-slot:header.type="{ header }">
            <span style="color: #00B082">{{ header.text }}</span>
          </template>
          <template v-slot:item.type="{ item }">
            <div v-if="item.type === 1">
                <v-chip color="#C7A8FF" text-color="white" label large style="width:80px">Service</v-chip>
            </div>
            <div v-if="item.type === 2">
                <v-chip color="#83D9FF" text-color="white" large label style="width:80px">Product</v-chip>
            </div>
            <div v-if="item.type === 3">
                <v-chip color="#B2FF59" text-color="white" large label style="width:80px">Free</v-chip>
            </div>
          </template>
          <template v-slot:item.revenue="{ item }">
            <span style="color: #4CD137">{{ item.revenue}}</span>
          </template>
          </v-data-table>

          <v-spacer></v-spacer><br>
          <v-pagination
            v-model="page"
            color="#00B082"
            :length="total_page"
            :total-visible="7"
            circle
            ></v-pagination>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    </div>
      <!-- <v-card width="100%">
          <v-row
          align="center"
          v-for="n in 1"
          :key="n"
          :class="n === 1 ? 'mb-6' : ''"
          no-gutters
          style="margin-left: 24px">
          <v-col :md="4" style="margin-right: 16px">
              <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 25px; padding-bottom: 35px;">
                  <div class="align-center text-center">
                  <div class="title font-regular" style="color: #BEC2C4">Total Merchant</div>
                  <span class="display-1 font-weight-bold" style="color: #00D79E">Rp. 1500</span>
                  </div>
              </v-card>
          </v-col>
          <v-col style="margin-right: 16px">
              <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 24px; padding-bottom: 5px;">
                  <v-row justify="center" align="center" class="mb-6" no-gutters>
                      <v-col :md="4">
                          <v-btn fab large color="#EBFCF7">
                              <v-icon color="#00B082" :large="true">mdi-deskphone</v-icon>
                          </v-btn>
                      </v-col>
                      <v-col :md="3" align-self="center">
                          <span class="headline font-weight-bold" style="color: #00D79E">1654</span><br>
                          <span class="caption font-regular" style="color: #BEC2C4">Post</span>
                      </v-col>
                  </v-row>
              </v-card>
          </v-col>
          <v-col style="margin-right: 16px">
              <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 24px; padding-bottom: 5px;">
                  <v-row justify="center" align="center" class="mb-6" no-gutters>
                      <v-col :md="4">
                          <v-btn fab large color="#EBFCF7">
                              <v-icon color="#00B082" :large="true">mdi-earth</v-icon>
                          </v-btn>
                      </v-col>
                      <v-col :md="3" align-self="center">
                          <span class="headline font-weight-bold" style="color: #00D79E">1654</span><br>
                          <span class="caption font-regular" style="color: #BEC2C4">Online</span>
                      </v-col>
                  </v-row>
              </v-card>
          </v-col>
          <v-col style="margin-right: 16px">
              <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 24px; padding-bottom: 5px;">
                  <v-row justify="center" align="center" class="mb-6" no-gutters>
                      <v-col :md="4">
                          <v-btn fab large color="#EBFCF7">
                              <v-icon color="#00B082" :large="true">mdi-calendar-range</v-icon>
                          </v-btn>
                      </v-col>
                      <v-col :md="4" align-self="center">
                          <span class="headline font-weight-bold" style="color: #00D79E">1654</span><br>
                          <span class="caption font-regular" style="color: #BEC2C4">Marketplace</span>
                      </v-col>
                  </v-row>
              </v-card>
          </v-col>
          </v-row>
              <p class="headline" style="margin-left: 24px">Merchant Transaction</p>
              <v-row align="center">
                  <v-col :md="4">
                      <ve-ring :data="dataRing" width="310px" :legend-visible="false" :settings="ringSettings" :colors="colorsRing" :title="title" />
                  </v-col>
                  <v-col>
                      <div v-for="(item, key) in merchantList" :key="key" style="min-height: 40px; margin-bottom: 8px; margin-right: 24px">
                          <div style="margin-bottom: 5px">
                              <span class="title font-weight-regular" style="color: #6A6A6A">{{ item.city }}</span>
                              <span class="title float-right font-weight-regular" style="color: #6A6A6A">{{ item.persen }} %</span>
                          </div>
                          <v-progress-linear :value="item.persen" height="6px" rounded :color="colorsRing[key]"></v-progress-linear>
                      </div>
                  </v-col>
              </v-row>
              <v-card class="pa-2" :shaped="true" >
              <v-card-title>
                  Acquisition Detail
                  <v-spacer></v-spacer>
                  <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  style="width: 10px;"
                  ></v-text-field>
                  <v-btn rounded outlined style="margin-right: 8px"><v-icon left>mdi-cloud-download</v-icon>Print</v-btn>
                  <v-btn rounded outlined style="margin-right: 8px"><v-icon left>mdi-printer</v-icon>Print</v-btn>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="desserts"
                  :search="search"
                  hide-default-footer
                  class="mytable"
                  style="border-bottom: none"
              >
              <template v-slot:header.type="{ header }">
                  <span style="color: #00B082">{{ header.text }}</span>
              </template>
              <template v-slot:item.pos="{ item }">
                  <div v-if="item.pos === 1">
                      <v-btn fab dark small color="#BEF4E6">
                      <v-icon color="#00B082">mdi-check</v-icon>
                      </v-btn>
                  </div>
                  <div v-else>
                      <v-btn fab dark small color="#f79892">
                      <v-icon color="red">mdi-close</v-icon>
                      </v-btn>
                  </div>
              </template>
              <template v-slot:item.online="{ item }">
                  <div v-if="item.online === 1">
                      <v-btn fab dark small color="#BEF4E6">
                      <v-icon color="#00B082">mdi-check</v-icon>
                      </v-btn>
                  </div>
                  <div v-else>
                      <v-btn fab dark small color="#f79892">
                      <v-icon color="red">mdi-close</v-icon>
                      </v-btn>
                  </div>
              </template>
              <template v-slot:item.marketplace="{ item }">
                  <div v-if="item.marketplace === 1">
                      <v-btn fab dark small color="#BEF4E6">
                      <v-icon color="#00B082">mdi-check</v-icon>
                      </v-btn>
                  </div>
                  <div v-else>
                      <v-btn fab dark small color="#f79892">
                      <v-icon color="red">mdi-close</v-icon>
                      </v-btn>
                  </div>
              </template>
              </v-data-table>

              <v-spacer></v-spacer><br>
              <div style="margin-top: 24px; margin-bottom: 24px">
                  <v-pagination
                  v-model="page"
                  color="#00B082"
                  :length="total_page"
                  :total-visible="7"
                  circle
                  ></v-pagination>
              </div>
              </v-card>
      </v-card> -->
  </v-container>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

export default {
  name: 'ListActivity',
  props: ['xaxis', 'extend', 'page', 'total_page'],

  data () {
    return {
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'POS',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Online',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Marketplace',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        }
      ],
      headers: [
        {
          text: 'Company',
          align: 'left',
          value: 'company'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'POS',
          align: 'center',
          value: 'pos'
        },
        {
          text: 'Online',
          align: 'center',
          value: 'online'
        },
        {
          text: 'Marketplace',
          align: 'center',
          value: 'marketplace'
        },
        {
          text: 'Total POS Device',
          align: 'center',
          value: 'total_pos_device'
        }
      ],
      desserts: [
        {
          company: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_pos_device: 12,
          pos: 1,
          online: 1,
          marketplace: 1
        },
        {
          company: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_pos_device: 12,
          pos: 0,
          online: 1,
          marketplace: 1
        },
        {
          company: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_pos_device: 12,
          pos: 1,
          online: 0,
          marketplace: 1
        },
        {
          company: 'Kakiku Reflexologi',
          city: 'Batam',
          total_pos_device: 12,
          pos: 1,
          online: 0,
          marketplace: 0
        }
      ],
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      tab2: null,
      itemsTab: [
        'All', 'POS', 'Online', 'Marketplace'
      ],
      dataLine: {
        columns: ['date', 'service', 'product', 'classes'],
        rows: [{ 'date': '10', 'service': 10, 'product': 5, 'classes': 2 },
          { 'date': '11', 'service': 20, 'product': 20, 'classes': 6 },
          { 'date': '12', 'service': 17, 'product': 16, 'classes': 5 },
          { 'date': '13', 'service': 28, 'product': 24, 'classes': 10 }
        ]
      },
      dataRing: {
        columns: ['pos', 'online', 'marketplace'],
        rows: [{ 'pos': 10, 'online': 5, 'marketplace': 2 },
          { 'pos': 20, 'online': 20, 'marketplace': 6 },
          { 'pos': 17, 'online': 16, 'marketplace': 5 }
        ]
      },
      colorsLine: ['#83D9FF', '#B2FF59', '#C7A8FF'],
      colorsRing: ['#00B082', '#00D79E', '#7CFDBE'],
      title: {
        text: 1500,
        textStyle: {
          color: '#00D79E',
          fontSize: '69',
          fontStyle: 'normal'
        },
        x: 'center',
        y: 'center'
      },
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        radius: [110, 150],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: [{
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#83D9FF'
          }, {
            offset: 1,
            color: '#f2f9fc'
          }])
        },
        {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#B2FF59'
          }, {
            offset: 1,
            color: '#cbf797'
          }])
        },
        {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#C7A8FF'
          }, {
            offset: 1,
            color: '#e5d9fa'
          }])
        }]
      },
      search: ''
    }
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    }
  }
}
</script>
