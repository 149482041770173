<template>
    <!-- <v-card>
        <v-card flat> -->
          <!-- <v-card> -->
            <v-container class="fill-height">
              <v-card class="mx-4 mb-4" :raised="true" width="100%">
                <v-card-title>
                  POS Revenue
                  <v-spacer></v-spacer>
                  <div>
                    <v-autocomplete
                    v-model="selectTime"
                    :items="dropdown_time"
                    solo
                    label="All Time"
                    background-color="#F5F5F5"
                    ></v-autocomplete>
                </div>
                </v-card-title>
                <v-row
                v-for="n in 1"
                :key="n"
                :class="n === 1 ? 'mb-6' : ''"
                no-gutters>
                <v-col style="margin-right: 16px">
                    <div class="fill-height mx-4">
                      <ve-ring :data="dataRing" width="300px" :legend-visible="false" :settings="ringSettings" :colors="colorsRing" :title="title" />
                    </div>
                </v-col>
                <v-col style="margin-right: 16px">
                    <div class="fill-height">
                      <div class="py-2" style="border: 2px solid #4CD137; border-radius: 8px; height: 56px">
                        <span class="title mx-4">Total Revenue</span>
                        <span class="headline font-weight-bold" style="color: #4CD137">Rp. 150.000.000,00</span>
                      </div>
                      <v-card class="mt-3" :raised="true" height="120px">
                        <v-row style="position: absolute; top: -12px">
                          <v-col>
                            <div class="pt-3 text-center" style="width: 144px; height: 120px; background: transparent linear-gradient(210deg, #00D79E 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box; border-radius: 8px 87px 85px 8px;">
                              <div class="mx-8 pa-3" style="background: #FFFFFF; width: 68px; height:68px; border-radius: 50%;">
                                <v-icon color="#FFE78F">$vuetify.icons.store_orange</v-icon>
                              </div>
                              <span class="body-1" style="color: #FFFFFF">Service</span>
                            </div>
                          </v-col>
                          <v-col>
                            <span class="caption" style="color: #BEC2C4">Services Booked</span><br>
                            <span class="subtitle-1 font-weight-black">9831</span>
                          </v-col>
                          <v-col>
                            <span class="caption" style="color: #E84118">Services Booked</span><br>
                            <span class="subtitle-1 font-weight-black" style="color: #E84118">9831</span>
                          </v-col>
                        </v-row>
                        <div style="position: absolute; left: 170px; top: 50px">
                          <div class="subtitle-1" style="color: #BEC2C4">Revenue</div>
                          <span class="headline font-weight-bold" style="color: #4CD137">Rp. 150.000.000,00</span>
                        </div>
                      </v-card>
                      <v-card class="mt-3" :raised="true" height="120px">
                        <v-row style="position: absolute; top: -12px">
                          <v-col>
                            <div class="pt-3 text-center" style="width: 144px; height: 120px; background: transparent linear-gradient(210deg, #00D79E 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box; border-radius: 8px 87px 85px 8px;">
                              <div class="mx-8 pa-3" style="background: #FFFFFF; width: 68px; height:68px; border-radius: 50%;">
                                <v-icon color="#FFE78F">$vuetify.icons.store_orange</v-icon>
                              </div>
                              <span class="body-1" style="color: #FFFFFF">Classes</span>
                            </div>
                          </v-col>
                          <v-col>
                            <span class="caption" style="color: #BEC2C4">Services Booked</span><br>
                            <span class="subtitle-1 font-weight-black">9831</span>
                          </v-col>
                          <v-col>
                            <span class="caption" style="color: #E84118">Services Booked</span><br>
                            <span class="subtitle-1 font-weight-black" style="color: #E84118">9831</span>
                          </v-col>
                        </v-row>
                        <div style="position: absolute; left: 170px; top: 50px">
                          <div class="subtitle-1" style="color: #BEC2C4">Revenue</div>
                          <span class="headline font-weight-bold" style="color: #4CD137">Rp. 150.000.000,00</span>
                        </div>
                      </v-card>
                    </div>
                </v-col>
                </v-row>
              </v-card>
              <!-- <v-card class="pa-2" width="100%"> -->
              <div class="mx-4 mt-5" style="width: 100%">
                <div style="height: 64px;">
                  <v-row class="mb-6" no-gutters>
                  <v-col :md="6">
                    <span class="title">  Merchant Transaction </span>
                  </v-col>
                  <v-col :md="4">
                      <div class="float-right" style="margin-right: 8px">
                      <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" >
                      <template v-slot:activator="{ on }">
                          <v-text-field
                          :value="computedDateFormattedMomentjs"
                          readonly
                          v-on="on"
                          solo
                          background-color="#F5F5F5"
                          style="width: 200px"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="date"
                          @click:date="setDate(0)"
                          range
                      ></v-date-picker>
                      </v-menu>
                      </div>
                  </v-col>
                  <v-col :md="2">
                      <v-autocomplete
                      v-model="selectCity"
                      :items="dropdown_font"
                      solo
                      label="All City"
                      background-color="#F5F5F5"
                      ></v-autocomplete>
                  </v-col>
                  </v-row>
              </div>
              <v-tabs
                v-model="tab2"
                align-with-title
                background-color="transparent">
                <v-tabs-slider color="yellow"></v-tabs-slider>

                <v-tab v-for="item in itemsTab" :key="item">
                {{ item }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab2">
                <v-tab-item>
                  <ve-line :data="dataLine" :legend-visible="true" :colors="colorsLine" :settings="lineSettings" :extend="extend" :xAxis="xaxis" :after-config="afterConfig" />
                  <v-card class="pa-2" :shaped="true" >
                    <v-card-title>
                      12456 Transaction
                      <v-spacer></v-spacer>
                      <v-btn rounded outlined style="margin-right: 8px"><v-icon left>mdi-cloud-download</v-icon>Print</v-btn>
                      <v-btn rounded outlined style="margin-right: 8px"><v-icon left>mdi-printer</v-icon>Print</v-btn>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headers"
                      :items="desserts"
                      :search="search"
                      hide-default-footer
                      class="mytable"
                      style="border-bottom: none"
                    >
                    <template v-slot:header.type="{ header }">
                      <span style="color: #00B082">{{ header.text }}</span>
                    </template>
                    <template v-slot:item.type="{ item }">
                      <div v-if="item.type === 1">
                          <v-chip color="#C7A8FF" text-color="white" label large style="width:80px">Service</v-chip>
                      </div>
                      <div v-if="item.type === 2">
                          <v-chip color="#83D9FF" text-color="white" large label style="width:80px">Product</v-chip>
                      </div>
                      <div v-if="item.type === 3">
                          <v-chip color="#B2FF59" text-color="white" large label style="width:80px">Free</v-chip>
                      </div>
                    </template>
                    <template v-slot:item.revenue="{ item }">
                      <span style="color: #4CD137">{{ item.revenue}}</span>
                    </template>
                    </v-data-table>

                    <v-spacer></v-spacer><br>
                    <v-pagination
                      v-model="page"
                      color="#00B082"
                      :length="total_page"
                      :total-visible="7"
                      circle
                      ></v-pagination>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
              </div>
              <!-- </v-card> -->
            </v-container>
            <!-- </v-card> -->
        <!-- </v-card>
    </v-card> -->
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

export default {
  name: 'ListTransaction',
  props: ['xaxis', 'extend', 'page', 'total_page'],

  data () {
    return {
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        }
      ],
      headers: [
        {
          text: 'Type',
          align: 'left',
          value: 'type'
        },
        {
          text: 'Name',
          align: 'left',
          value: 'service_name'
        },
        {
          text: 'Company',
          align: 'left',
          value: 'company_name'
        },
        {
          text: 'Platform',
          align: 'left',
          value: 'platform'
        },
        {
          text: 'Date',
          align: 'left',
          value: 'date'
        },
        {
          text: 'Revenue',
          align: 'left',
          value: 'revenue'
        }
      ],
      desserts: [
        {
          company_name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS',
          joined_time: '13.00',
          date: '21 Agustus 2019 13:00',
          type: 1,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          time_left: '2 days',
          member_status: 1
        },
        {
          company_name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 2,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          time_left: '2 days',
          member_status: 2
        },
        {
          company_name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online, Marketplace',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 3,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          time_left: '2 days',
          member_status: 1
        },
        {
          company_name: 'Kakiku Reflexologi',
          city: 'Batam',
          total_staff: 12,
          platform: 'POS',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 1,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          time_left: '2 days',
          member_status: 1
        }
      ],
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      dropdown_time: ['All Time', 'Today', 'Yesterday', 'Last Week'],
      selectCity: '',
      tab2: null,
      itemsTab: [
        'All', 'POS', 'Online', 'Marketplace'
      ],
      dataLine: {
        columns: ['date', 'service', 'product', 'classes'],
        rows: [{ 'date': '10', 'service': 10, 'product': 5, 'classes': 2 },
          { 'date': '11', 'service': 20, 'product': 20, 'classes': 6 },
          { 'date': '12', 'service': 17, 'product': 16, 'classes': 5 },
          { 'date': '13', 'service': 28, 'product': 24, 'classes': 10 }
        ]
      },
      colorsLine: ['#83D9FF', '#B2FF59', '#C7A8FF'],
      series: [{
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: '#83D9FF'
        }, {
          offset: 1,
          color: '#f2f9fc'
        }])
      },
      {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: '#B2FF59'
        }, {
          offset: 1,
          color: '#cbf797'
        }])
      },
      {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: '#C7A8FF'
        }, {
          offset: 1,
          color: '#e5d9fa'
        }])
      }],
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        radius: [80, 150],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      search: '',
      dataRing: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 }
        ]
      },
      colorsRing: ['#00B082', '#7CFDBE', '#A1FFD1', '#C5DBFF'],
      title: {
        text: 125,
        textStyle: {
          color: '#00B082',
          fontSize: '59',
          fontStyle: 'normal'
        },
        x: 'center',
        y: 'center'
      },
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#E74C3C'
          }, {
            offset: 1,
            color: '#FFFFFF'
          }])
        }
      }
    }
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },
    afterConfig (options) {
      options.series[0].areaStyle = {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      }
      options.series[1].areaStyle = {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#FFB677'
          }, {
            offset: 1,
            color: '#ffd0a8'
          }])
        }
      }
      return options
    }
  }
}
</script>
