<template>
  <div>
    <v-container class="grey lighten-5 fill-height">
      <div style="background: #ffffff; box-shadow: 0px 0px 12px #0000000A; border-radius: 16px;">
        <v-tabs
            v-model="tab"
            align-with-title
            color="#00D79E"
            background-color="transparent">
            <v-tabs-slider color="#00D79E"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item.id" class="text-capitalize">
            {{ item.name }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <list-merchant :extend="extend" :xaxis="xAxis" :page="page" :total_page="total_page"></list-merchant>
          </v-tab-item>
          <v-tab-item>
              <list-activity :extend="extend" :xaxis="xAxis" :page="page" :total_page="total_page"></list-activity>
          </v-tab-item>
          <v-tab-item>
              <list-transaction :extend="extend" :xaxis="xAxis" :page="page" :total_page="total_page"></list-transaction>
              <!-- <list-acqusition :extend="extend" :xaxis="xAxis" :page="page" :total_page="total_page"></list-acqusition> -->
          </v-tab-item>
          <v-tab-item>
            <list-acqusition :extend="extend" :xaxis="xAxis" :page="page" :total_page="total_page"></list-acqusition>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'
// import echarts from 'echarts/lib/echarts'

import ListActivity from './_list-activity'
import ListTransaction from './_list-transaction'
import ListAcqusition from './_list-acqusition'
import ListMerchant from './_list-merchant'
export default {
  name: 'ReportMerchant',
  components: {
    ListActivity,
    ListTransaction,
    ListAcqusition,
    ListMerchant
  },
  data () {
    return {
      tab: null,
      items: [
        {
          id: 0,
          name: 'Merchant List'
        },
        {
          id: 1,
          name: 'Activity'
        },
        {
          id: 2,
          name: 'Transaction'
        },
        {
          id: 3,
          name: 'Acquisition'
        }
      ],
      // alignments: [
      //   'center'
      // ],
      xAxis: {
        type: 'category',
        boundaryGap: false
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 15
      },
      // histogramSettings: {
      //   metrics: ['confirmed', 'cancelled'],
      //   stack: { 'sales': ['confirmed', 'cancelled'] }
      // },
      // colorsHisto: ['#00D79E', '#F5295A'],
      // dataUpcomingAppointment: {
      //   columns: ['date', 'confirmed', 'cancelled'],
      //   rows: [{ 'date': 'Mon 10', 'confirmed': 2, 'cancelled': 2 },
      //     { 'date': 'Tue 11', 'confirmed': 2, 'cancelled': 0 },
      //     { 'date': 'Wed 12', 'confirmed': 4, 'cancelled': 0 },
      //     { 'date': 'Thr 13', 'confirmed': 2, 'cancelled': 0 },
      //     { 'date': 'Fri 14', 'confirmed': 6, 'cancelled': 2 },
      //     { 'date': 'Sat 15', 'confirmed': 7, 'cancelled': 0 },
      //     { 'date': 'Sun 16', 'confirmed': 3, 'cancelled': 2 }
      //   ]
      // },
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu2: false,
      page: 1,
      total_page: 10
    }
  },

  computed: {
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    },
    valueData () {
      var ret = []
      ret[0] = this.value1[0]
      ret[1] = this.value1[1]
      return ret
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },
    testSlider (data) {
      console.log('data', data)
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>
