<template>
    <v-card flat>
        <div>
        <v-container class="fill-height">
            <v-row
            v-for="n in 1"
            :key="n"
            :class="n === 1 ? 'mb-6' : ''"
            no-gutters>
            <v-col style="margin-right: 16px">
                <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 20px; padding-bottom: 5px; max-height: 260px">
                    <v-row class="mb-6 align-center" no-gutters>
                        <v-col :md="3" class="text-center">
                            <div class="pa-2 text-center">
                            <v-avatar class="pa-3" size="88" tile>
                                <v-icon size="88">$vuetify.icons.store_green</v-icon>
                            </v-avatar>
                            <div class="font-weight-bold">580</div>
                            <v-spacer></v-spacer>
                            <span class="body-2" style="color: #BEC2C4">{{ lang.t('$vuetify.total') }} {{ lang.t('$vuetify.merchant') }}</span>
                            </div>
                        </v-col>
                        <v-col :md="6" >
                            <div v-for="item in merchantList" :key="item" style="min-height: 40px; margin-bottom: 8px">
                                <div style="margin-bottom: 5px">
                                    <span>{{ item.city }}</span>
                                    <span class="float-right">{{ item.persen }} %</span>
                                </div>
                                <v-progress-linear color="#FFCB9F" :value="item.persen"></v-progress-linear>
                            </div>
                        </v-col>
                        <v-col :md="2" style="padding-left: 5px;">
                            <div v-for="item in merchantList" :key="item" class="text-right" style="height: 40px; margin-bottom: 8px">
                                <span style="color: #00D79E">{{ item.stok }}</span>
                                <p class="caption" style="color: #BEC2C4">{{ item.status }}</p>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col >
                <v-card class="align-center" :shaped="true" :raised="true" style="max-height: 260px">
                    <v-row class="align-center" no-gutters>
                        <v-col :md="6" style="margin-top: 24px" >
                            <div style="height: 250px; margin-left: 5px">
                            <ve-ring :data="dataLine" :title="title" height="220px" :legend-visible="false" :settings="ringSettings" :colors="colorsRing" />
                            </div>
                        </v-col>
                        <v-col :md="5" style="margin-left: 32px; margin-top: 16px" >
                            <span class="title">12 {{ lang.t('$vuetify.new_merchant') }}</span>
                            <v-spacer></v-spacer>
                            <p class="subtitle" style="color: #BEC2C4;">from last 7 days</p>
                            <div v-for="(item, id) in merchantList" :key="id" style="min-height: 17px; margin-bottom: 8px">
                                <div>
                                    <span :style="'color:' + colorsRing[id]">{{ item.persen }}% </span>
                                    <span>{{ item.city }}</span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            </v-row>
            <v-card class="pa-2" :shaped="true" :raised="true" width="100%">
            <v-card-title style="height: 64px">
                <v-row class="mb-6" no-gutters>
                <v-col :md="6">
                    {{ lang.t('$vuetify.merchant_list') }}
                </v-col>
                <v-col :md="4">
                    <div class="float-right" style="margin-right: 8px">
                    <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                        :value="computedDateFormattedMomentjs"
                        readonly
                        v-on="on"
                        solo
                        background-color="#F5F5F5"
                        style="width: 200px"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        @click:date="setDate(0)"
                        range
                    ></v-date-picker>
                    </v-menu>
                    </div>
                </v-col>
                <v-col :md="2">
                    <v-autocomplete
                    v-model="selectCity"
                    :items="dropdown_font"
                    solo
                    label="All City"
                    background-color="#F5F5F5"
                    ></v-autocomplete>
                </v-col>
                </v-row>
            </v-card-title>
            <v-tabs
                v-model="tab2"
                align-with-title
                color="#00D79E"
                background-color="transparent">
                <v-tabs-slider color="#00D79E"></v-tabs-slider>

                <v-tab v-for="item in itemsTab" :key="item">
                {{ item }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab2">
                <v-tab-item>
                <!-- <p class="font-weight-bold headline" style="margin-left: 16px; margin-top: 16px"> Rp. 15.000.000,00 </p> -->
                <ve-line :data="dataLine" :legend-visible="false" :colors="colorsLine" :settings="lineSettings" :extend="extend" :xAxis="xaxis" :after-config="afterConfig" />
                <span class="title ml-6" style="color: #0984E3">{{ lang.t('$vuetify.hourly_activity') }}</span>
                <div class="float-right" style="width: 268px">
                    <!-- <span>{{ valueData }}</span> -->
                    <!-- <v-range-slider v-model="value1" @change="testSlider"/> -->
                    <vue-range-slider v-model="value1" :label="formatter" :min="min" :max="max" :formatter="formatter" :tooltip-merge="tooltipMerge" :enable-cross="enableCross"></vue-range-slider>
                </div>
                <ve-line class="mx-6" :data="dataLineHours" :legend-visible="false" :colors="colorsLineHours" :settings="lineSettingsHours" :extend="extend" :xAxis="xaxis" :after-config="afterConfig" />
                <v-card class="pa-2" :shaped="true" >
                    <v-card-title>
                    12 {{ lang.t('$vuetify.merchant') }}
                    <v-spacer></v-spacer>
                    <v-btn rounded outlined class="text-capitalize" style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>Download</v-btn>
                    <v-btn rounded outlined class="text-capitalize" style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>Print</v-btn>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                    ></v-text-field>
                    </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="desserts"
                    :search="search"
                    hide-default-footer
                    class="mytable"
                    style="border-bottom: none"
                    @click:row="rowClick"
                    >
                    <template v-slot:header.name="{ header }">
                    <span style="color: #00B082">{{ header.text }}</span>
                    </template>
                    <template v-slot:item.name="{ item }">
                    {{ item.name }}
                    </template></v-data-table>

                    <v-spacer></v-spacer><br>
                    <v-pagination
                    v-model="page"
                    color="#00B082"
                    :length="total_page"
                    :total-visible="7"
                    circle
                    ></v-pagination>
                </v-card>
                </v-tab-item>
            </v-tabs-items>
            </v-card>
        </v-container>
        </div>
    </v-card>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

export default {
  name: 'ListMerchant',
  props: ['xaxis', 'extend', 'page', 'total_page'],

  data () {
    return {
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        }
      ],
      colorsRing: ['#FFCB9F', '#FFE78F', '#A1FFD1', '#C5DBFF'],
      menu1: false,
      menu2: false,
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      selectCity: '',
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      tab2: null,
      itemsTab: [
        'All', 'POS', 'Online', 'Marketplace'
      ],
      dataLine: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 },
          { 'date': '12', 'sales': 30 },
          { 'date': '13', 'sales': 40 }
        ]
      },
      title: {
        text: 12,
        textStyle: {
          color: '#333333',
          fontSize: '36',
          fontStyle: 'normal'
        },
        x: 'center',
        y: 'center'
      },
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 110,
        radius: [60, 100],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      colorsLine: ['#00D79E', '#1DA1F3'],
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      value1: [0, 10],
      min: 0,
      max: 24,
      tooltipMerge: false,
      enableCross: false,
      dataLineHours: {
        columns: ['time', 'sales'],
        rows: [{ 'time': '10.00', 'sales': 20 },
          { 'time': '11.00', 'sales': 45 },
          { 'time': '12.00', 'sales': 30 },
          { 'time': '13.00', 'sales': 40 },
          { 'time': '14.00', 'sales': 40 },
          { 'time': '15.00', 'sales': 40 },
          { 'time': '16.00', 'sales': 40 },
          { 'time': '17.00', 'sales': 40 },
          { 'time': '18.00', 'sales': 40 },
          { 'time': '19.00', 'sales': 40 },
          { 'time': '20.00', 'sales': 40 },
          { 'time': '21.00', 'sales': 40 },
          { 'time': '22.00', 'sales': 0 }
        ]
      },
      colorsLineHours: ['#0984E3', '#1DA1F3'],
      lineSettingsHours: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#0984E3'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      search: '',
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.merchant_name'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.total') + ' ' + this.$vuetify.lang.t('$vuetify.staff'),
          align: 'center',
          value: 'total_staff'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.platform'),
          align: 'left',
          value: 'platform'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.city'),
          align: 'left',
          value: 'city'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.time_joined'),
          align: 'right',
          value: 'time_joined'
        }
      ],
      desserts: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online, Marketplace',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          total_staff: 12,
          platform: 'POS',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        }
      ]
    }
  },

  created () {
    this.formatter = value1 => `${value1}:00`
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    },

    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },
    afterConfig (options) {
      options.series[0].symbolSize = 16
      return options
    },
    rowClick (row) {
      this.$router.push('/report/report_merchant/2')
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>
